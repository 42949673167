import request from '@/utils/request'

// 创建账号
export function createAccount (params) {
  return request.post('/api/createAccount', params)
}

// 修改账号
export function editAccount (params) {
  return request.post('/api/editAccount', params)
}

// 账号列表
export function listAccount (params) {
  return request.post('/api/listAccount', params)
}

// 账号详情
export function infoAccount (params) {
  return request.post('/api/infoAccount', params)
}


