<template>
  <div>
    <el-dialog
      :title="title"
      width="520px"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      custom-class="userModal"
      :before-close="handleClose"
    >
      <el-form
        :model="form"
        ref="submitForm"
        size="small"
        :rules="rules"
        label-width="100px"
      >
        <el-form-item label="姓名：" prop="username">
          <el-input
            v-model="form.username"
            style="width: 100%"
            placeholder="请输入姓名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="mobile">
          <el-input
            v-model="form.mobile"
            style="width: 100%"
            placeholder="请输入手机号"
            maxlength="11"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码：" v-if="!userId" prop="password">
          <el-input
            v-model="form.password"
            style="width: 100%"
            placeholder="请输入密码"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="状态：" prop="accountStatus">
          <el-radio-group v-model="form.accountStatus">
            <el-radio :label="1">正常</el-radio>
            <el-radio :label="2">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="角色：" prop="appointRoleIdList">
          <el-checkbox-group
            v-model="form.appointRoleIdList"
            @change="onRoleIdChange"
          >
            <el-checkbox
              v-for="item in roleList"
              :key="item.id"
              :label="item.id"
              name="type"
              >{{ item.role_name }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close()" size="small">取 消</el-button>
        <el-button type="primary" @click="confirm('submitForm')" size="small"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { allRoleList } from "@/api/role.js";
// 
import { infoAccount, createAccount, editAccount } from "@/api/user.js";
import { JSEncrypt } from "jsencrypt";
export default {
  name: "userModal",

  data() {
    let checkPhone = (rule, value, callback) => {
      let reg = /^1[345789]\d{9}$/;
      if (!reg.test(value)) {
        callback(new Error("请输入11位手机号"));
      } else {
        callback();
      }
    };
    return {
      title: "",
      userId: "",
      dialogFormVisible: false,
      form: {
        appointRoleId: "",
        accountStatus: 1,
        appointRoleIdList: [],
      },
      pwd: "",
      roleList: [],
      rules: {
        username: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        mobile: [
          { required: true, message: "请输入正确的手机号", trigger: "blur" },
          {
            type: "number",
            validator: checkPhone,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        appointRoleIdList: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个角色",
            trigger: "change",
          },
        ],

        accountStatus: [
          { required: true, message: "请选择状态", trigger: "change" },
        ],
      },
    };
  },

  mounted() {},

  methods: {
    async isShow(type, userId) {
      this.dialogFormVisible = true;
      await this.getAllRoleList();
      if (type == "add") {
        this.title = "新增账号";
        this.userId = '';
        this.form.editManagerId = '';
      } else {
        this.title = "修改账号";
        this.userId = userId;
        this.form.editManagerId = userId;
        this.getinfoAccount();
      }
      console.log("isShow", type, this.userId)
    },
    async getinfoAccount() {
      const { code, data } = await infoAccount({
        manager_id: this.userId,
      });
      if (code == 200) {
        var appointRoleIdList = [];
        data.role_id.split(",").map((item) => {
          appointRoleIdList.push(Number(item));
        });
        this.form = {
          editManagerId: data.id,
          username: data.username,
          mobile: data.mobile,
          accountStatus: data.status,
          appointRoleId: data.role_id,
          appointRoleIdList: appointRoleIdList,
        };
      }
    },
    confirm(submitForm) {
      this.$refs[submitForm].validate(async (valid) => {
        if (valid) {
          const parme = {
            ...this.form,
            password: this.passwordEncryption(this.form.password),
          };
          let res;
          this.userId
            ? (res = await editAccount(parme))
            : (res = await createAccount(parme));
          if (res.code == 200) {
            this.close();
            this.$parent.initData();
            this.$message.success("操作成功");
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    close() {
      this.dialogFormVisible = false;
      this.$refs["submitForm"].resetFields();
    },
    onRoleIdChange(val) {
      this.form.appointRoleId = val.join(",");
    },
    async getAllRoleList() {
      const { code, data } = await allRoleList();
      if (code == 200) {
        this.roleList = data;
      }
    },
    passwordEncryption(passwordUser) {
      let publicKey =
        "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDiHQgcc55lLY/s6cfF+teZ8chBskyTMWwo1ai6nWMxjZh1EijuaenXbJu9DsJy579DGlP2Y8+0cAfCZLYXbp8ab1XANbH4ubqkq5eV9drBbCAIvDZeCS65MaHg3zln/AbTIDxfVEbGcI/g3kRQF7X0Pp7YfABZ6o04SqRfaV8npwIDAQAB";
      let encryptor = new JSEncrypt();
      encryptor.setPublicKey(publicKey);
      let passwordEncryp = encryptor.encrypt(passwordUser);
      return passwordEncryp;
    },
    handleClose(done) {
      this.close();
      done();
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .userModal {
  border-radius: 10px !important;
  .el-dialog__header {
    border-bottom: 1px solid #e8eaec;
    .el-dialog__title {
      font-size: 14px;
      color: #17233d;
      font-weight: 700;
    }
  }
  .el-dialog__footer {
    border-top: 1px solid #e8eaec;
  }
}
</style>