<template>
  <div class="userList">
    <div class="search-bar">
      <el-form
        ref="searchRef"
        :model="searchForm"
        label-width="80px"
        size="small"
      >
        <div class="search-group-wrap flex">
          <el-row class="flex-1">
            <el-col :span="6">
              <el-form-item label="姓名">
                <el-input
                  v-model="searchForm.username"
                  placeholder="请输入姓名"
                  clearable
                  @keydown.enter.native="serchData"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="手机号">
                <el-input
                  v-model="searchForm.mobile"
                  placeholder="请输入手机号"
                  clearable
                  @keydown.enter.native="serchData"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="角色">
                <el-select
                  style="width: 100%"
                  v-model="searchForm.role_id"
                  clearable
                  placeholder="请选择角色"
                  @change="serchData"
                >
                  <el-option
                    v-for="item in roleList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="账号状态">
                <el-select
                  v-model="searchForm.status"
                  placeholder="请选择"
                  style="width: 100%"
                  clearable
                  @change="serchData"
                >
                  <el-option
                    v-for="item in statusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="inben ml_20">
            <el-button type="primary" size="small" @click="serchData"
              >搜索</el-button
            >
          </div>
        </div>
      </el-form>
    </div>
    <div class="derive">
      <el-button
        size="small"
        style="float: right; margin-bottom: 20px"
        type="primary"
        @click="addUser()"
        v-if="managerType != 0 || getAuth('adminOperate')"
        >新增用户</el-button
      >
    </div>
    <base-table
      :columns="userlCol"
      :data="tableData"
      :pagination="searchForm"
      :total="total"
      @getData="initData"
      @changeCurrent="selectChange"
      :loading="loading"
      :isPaginationShow="true"
      wrapperHeight="calc(100% - 45px)"
    >
      <!-- <el-table-column slot="select" type="selection" width="55" fixed="left" align='center'></el-table-column> -->
      <el-table-column
        slot="operation"
        label="操作"
        align="center"
        width="120"
        fixed="right"
        v-if="managerType != 0 || (getAuth('adminOperate') || getAuth('editPwd'))"
      >
        <template slot-scope="scope">
          <el-button type="text" v-if="managerType != 0 || getAuth('adminOperate')" size="small" @click="amend(scope.row)"
            >修改</el-button
          >
          <el-button type="text" v-if="managerType != 0 || getAuth('editPwd')" size="small" @click="editPwd(scope.row)"
            >修改密码</el-button
          >
          <!-- <span style="margin:0px 10px;color:#7cb8fe">|</span> -->
          <!-- <el-button type="text" size="small" @click="cancel(scope.row)">注销</el-button> -->
        </template>
      </el-table-column>
    </base-table>
    <user-modal ref="userModal"/>
    <edit-pwd ref='editPwd'/>
  </div>
</template>

<script>
import columns from "./columns";
import { listAccount } from "@/api/user.js";
import { allRoleList } from "@/api/role.js";
import userModal from './components/user-modal.vue';
import { getAuth } from "@/utils/index.js";
import EditPwd from './components/edit-pwd.vue';
export default {
  components: { userModal, EditPwd },
  name: "userList",

  data() {
    return {
      managerType: localStorage.getItem("managerType"),
      getAuth: getAuth,
      ...columns,
      secondaryBranchList: [],
      thirdlyBranchList: [],
      roleList: [],
      tableData: [],
      total: 0,
      loading: false,
      searchForm: {
        page: 1,
        per_page: 10,
        status:1,
      },
      statusList: [
        {
          value: 1,
          label: "正常",
        },
        {
          value: 2,
          label: "禁用",
        },
      ],
    };
  },

  mounted() {
    this.initData();
    this.getAllRoleList();
  },

  methods: {
    serchData(){
      this.searchForm.page = 1
      this.searchForm.per_page = 10
      this.initData()
    },
    async initData() {
      const { code, data } = await listAccount(this.searchForm);
      if (code == 200) {
        this.tableData = data.data.map((item) => {
          item.status_text = item.status == 1 ? "正常" : "禁用";
          return item;
        });
        this.total = data.total;
      }
    },
    async getAllRoleList() {
      const { code, data } = await allRoleList();
      if (code == 200) {
        this.roleList = data.map((item) => {
          item.value = item.id;
          item.label = item.role_name;
          return item;
        });
      }
    },
    selectChange(currentRow) {
      console.log(currentRow);
    },
    amend(row) {
      // const routeData = this.$router.resolve({
      //   path: "/user/manage",
      //   query: {
      //     userId: row.id,
      //   },
      // });
      // window.open(routeData.href, "_blank");
      this.$refs.userModal.isShow('edit',row.id)
    },
    editPwd(row){
      this.$refs.editPwd.isShow(row.id)
    },
    cancel(row) {
      console.log(row);
    },
    addUser() {
      // const routeData = this.$router.resolve({
      //   path: "/user/manage",
      // });
      // window.open(routeData.href, "_blank");
      this.$refs.userModal.isShow('add')
    },
  },
};
</script>

<style lang="scss" scoped>
.userList {
  .derive {
    margin-bottom: 10px;
  }
}
</style>