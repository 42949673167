<template>
  <div>
    <el-dialog
      :title="title"
      width="520px"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      custom-class="userModal"
      :before-close="handleClose"
    >
      <el-form
        :model="form"
        ref="submitForm"
        size="small"
        label-width="100px"
      >
        <el-form-item label="新密码：" prop="password">
          <el-input
            v-model="form.password"
            style="width: 100%"
            placeholder="请输入新密码"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close()" size="small">取 消</el-button>
        <el-button type="primary" @click="confirm('submitForm')" size="small"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
// editAccount
import { infoAccount,editAccount  } from "@/api/user.js";
import { JSEncrypt } from "jsencrypt";
export default {
  name: "userModal",
  data() {
    return {
      title: "",
      userId: "",
      dialogFormVisible: false,
      form: {
      },
      pwd: "",
    };
  },

  mounted() {},

  methods: {
    async isShow(userId) {
      this.dialogFormVisible = true;
      this.title = "修改账号";
      this.userId = userId;
      this.form.editManagerId = userId;
      this.getinfoAccount();
    },
    async getinfoAccount() {
      const { code, data } = await infoAccount({
        manager_id: this.userId,
      });
      if (code == 200) {
        var appointRoleIdList = [];
        data.role_id.split(",").map((item) => {
          appointRoleIdList.push(Number(item));
        });
        this.form = {
          editManagerId: data.id,
          username: data.username,
          mobile: data.mobile,
          accountStatus: data.status,
          appointRoleId: data.role_id,
          appointRoleIdList: appointRoleIdList,
        };
      }
    },
    confirm(submitForm) {
      this.$refs[submitForm].validate(async (valid) => {
        if (valid) {
          const parme = {
            ...this.form,
            password: this.passwordEncryption(this.form.password),
          };
          const {code} = await editAccount(parme)
          if(code == 200){
            this.close();
            this.$parent.initData();
            this.$message.success("操作成功");
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    close() {
      this.dialogFormVisible = false;
      this.$refs["submitForm"].resetFields();
    },
    passwordEncryption(passwordUser) {
      let publicKey =
        "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDiHQgcc55lLY/s6cfF+teZ8chBskyTMWwo1ai6nWMxjZh1EijuaenXbJu9DsJy579DGlP2Y8+0cAfCZLYXbp8ab1XANbH4ubqkq5eV9drBbCAIvDZeCS65MaHg3zln/AbTIDxfVEbGcI/g3kRQF7X0Pp7YfABZ6o04SqRfaV8npwIDAQAB";
      let encryptor = new JSEncrypt();
      encryptor.setPublicKey(publicKey);
      let passwordEncryp = encryptor.encrypt(passwordUser);
      return passwordEncryp;
    },
    handleClose(done) {
      this.close();
      done();
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .userModal {
  border-radius: 10px !important;
  .el-dialog__header {
    border-bottom: 1px solid #e8eaec;
    .el-dialog__title {
      font-size: 14px;
      color: #17233d;
      font-weight: 700;
    }
  }
  .el-dialog__footer {
    border-top: 1px solid #e8eaec;
  }
}
</style>