import request from '@/utils/request'

export function allRoleList (params) {
  return request.post('/api/allRoleList', params)
}

// 添加角色
export function createRole (params) {
  return request.post('/api/createRole', params)
}

// 修改角色
export function editRole (params) {
  return request.post('/api/editRole', params)
}