export const userlCol = [
  // {
  //   label: "选择",
  //   slot: 'select'
  // },
  {
    label: "姓名",
    prop: 'username',
    align: 'center',
  },
  {
    label: "账号",
    prop: 'mobile',
    align: 'center',
  },
  {
    label: "创建时间",
    prop: 'create_time',
    align: 'center',
  },
  {
    label: "修改时间",
    prop: 'update_time',
    align: 'center',
  },
  {
    label: "角色",
    prop: 'role_name',
    align: 'center',
  },
  {
    label: "状态",
    prop: 'status_text',
    align: 'center',
    width: 80,
  },
  {
    label: "操作",
    slot: 'operation'
  },
]
export default {
  userlCol,
}